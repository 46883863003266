import React, { ReactNode } from 'react';
import Icon from '../icons/Icons';

interface customProps {
  closeModal(): void;
  modalData?: any;
  customClass?: any;
  zindex?: any;
  children: ReactNode;
  closeIcon?: boolean;
  backIcon?: boolean;
  backButton(): void;
}

const Modal = (props: customProps) => {
  return (
    <div className={`${props?.zindex ? props?.zindex : 'z-50'} fixed inset-0 flex items-center justify-center bg-black bg-opacity-50`}>
      <div className={`bg-white p-8 rounded-xl relative shadow-lg m-7 w-full md:max-w-[654px] mobileModal ${props?.customClass ? props?.customClass : ''}`}>
        {props?.backIcon && props?.backButton && (
          <span onClick={() => {
            props?.backButton()
          }} className='cursor-pointer'>
            <Icon name='backarrow' />
          </span>
        )}
        {props.closeIcon &&
          <button
            className="absolute top-6 right-6 text-gray-500 hover:text-gray-700"
            onClick={() => props.closeModal()}
          >
            <Icon name='close' />
          </button>
        }
        <div className="modal-content w-full mx-auto">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
